/**
 * Created by Bo on 17-Jul-17.
 */

/**
 * Description: Error types are used to indicate an error from an epic call.
 * They are used together with the error reducer and sent as payload. Inside the
 * reducer they are coerced to a true boolean in case of an error or false if
 * sent with the reset action.
 *
 *  Convention: epicMethodName: 'reducername.epicMethodName'
 *  Example: userLogin: 'auth.userLogin'
 *
 * */
export interface GlobalErrorTypes {
    userLogin: string;
    userLogout: string;
    userSignup: string;
    confirmLogin: string;
    googleLogin: string;
    bnetLogin: string;
    owGetCourses: string;
    owCoursesGet: string;
    getStripePublishableKey: string;
    createNewStripeSubscription: string;
    createNewBraintreeSubscription: string;
    initializePasswordReset: string;
    resetPassword: string;
    changePassword: string;
    validatePromoCode: string;
    validateCampaign: string;
    trackVisit: string;
    getAffiliateEarningsData: string;
    updateAccountProfile: string;
    updateEmailPreferences: string;
    googleDisconnect: string;
    facebookDisconnect: string;
    battleNetDisconnect: string;
    discordDisconnect: string;
    signUserProfileImageUrl: string;
    resizeUserProfileImage: string;
    newUserProfileImageId: string;
    getUserBilling: string;
    cancelUserSubscription: string;
    getUserSubscriptionProration: string;
    changeUserSubscriptionPlan: string;
    getUserCustomers: string;
    getUserSubscriptions: string;
    getUserPayments: string;
    getUserPaymentMethods: string;
    addUserPaymentMethod: string;
    updateUserPaymentMethod: string;
    removeUserPaymentMethod: string;
    getSignedS3Url: string;
    resizeImage: string;
    getEmailPreferences: string;
    updateUser: string;
    updateConsentGrants: string;
    updateNotificationPreferences: string;
    getDrawing: string;
    newDrawing: string;
    enrollDrawing: string;
    getDrawingWinner: string;
    getAffiliateEvents: string;
    getAffiliatePayments: string;
    getAffiliateCampaigns: string;
    getEnabledBannerCampaigns: string;
    getEnabledModalCampaigns: string;
    getAffiliatePromoCodes: string;
    getGCSObjectsSignedURL: string;
    getGCSObjects: string;
    moveGCSObjects: string;
    copyGCSObjects: string;
    deleteGCSObjects: string;
    downloadGCSObjects: string;
    getGCSFile: string;
    uploadGCSObjectToYoutube: string;
}

export const GlobalErrorTypes: GlobalErrorTypes = {
    userLogin: 'auth.userLogin',
    userLogout: 'auth.userLogout',
    userSignup: 'auth.userSignup',
    confirmLogin: 'auth.confirmLogin',
    googleLogin: 'auth.googleLogin',
    bnetLogin: 'auth.bnetLogin',
    initializePasswordReset: 'auth.initializePasswordReset',
    resetPassword: 'auth.resetPassword',
    changePassword: 'auth.changePassword',
    owGetCourses: 'owContent.getCourses',
    getStripePublishableKey: 'payment.getStripePublishableKey',
    createNewStripeSubscription: 'payment.createNewStripeSubscription',
    createNewBraintreeSubscription: 'payment.createNewBraintreeSubscription',
    validatePromoCode: 'referral.validatePromoCode',
    validateCampaign: 'referral.validateCampaign',
    trackVisit: 'referral.trackVisit',
    getAffiliateEarningsData: 'referral.getAffiliateEarningsData',
    owCoursesGet: 'owContent.coursesGet',
    updateAccountProfile: 'user.updateAccountProfile',
    updateEmailPreferences: 'user.updateEmailPreferences',
    googleDisconnect: 'user.googleDisconnect',
    facebookDisconnect: 'user.facebookDisconnect',
    battleNetDisconnect: 'user.battleNetDisconnect',
    discordDisconnect: 'user.discordDisconnect',
    signUserProfileImageUrl: 'user.signUserProfileImageUrl',
    resizeUserProfileImage: 'user.resizeUserProfileImage',
    newUserProfileImageId: 'user.newUserProfileImageId',
    getUserBilling: 'user.getUserBilling',
    cancelUserSubscription: 'user.cancelUserSubscription',
    getUserSubscriptionProration: 'user.getUserSubscriptionProration',
    changeUserSubscriptionPlan: 'user.changeUserSubscriptionPlan',
    getUserCustomers: 'payment.getUserCustomers',
    getUserSubscriptions: 'payment.getUserSubscriptions',
    getUserPayments: 'payment.getUserPayments',
    getUserPaymentMethods: 'payment.getUserPaymentMethods',
    addUserPaymentMethod: 'payment.addUserPaymentMethod',
    updateUserPaymentMethod: 'payment.updateUserPaymentMethod',
    removeUserPaymentMethod: 'payment.removeUserPaymentMethod',
    getSignedS3Url: 'content.getSignedS3Url',
    resizeImage: 'content.resizeImage',
    getEmailPreferences: 'notifications.getEmailPreferences',
    updateConsentGrants: 'user.updateConsentGrants',
    updateUser: 'user.updateUser',
    updateNotificationPreferences: 'notifications.updateNotificationPreferences',
    getDrawing: 'referrals.getDrawing',
    newDrawing: 'referrals.newDrawing',
    enrollDrawing: 'referrals.enrollDrawing',
    getDrawingWinner: 'referrals.getDrawingWinner',
    getAffiliateEvents: 'referrals.getAffiliateEvents',
    getAffiliatePayments: 'referrals.getAffiliatePayments',
    getAffiliateCampaigns: 'referrals.getAffiliateCampaigns',
    getEnabledBannerCampaigns: 'referrals.getEnabledBannerCampaigns',
    getEnabledModalCampaigns: 'referrals.getEnabledModalCampaigns',
    getAffiliatePromoCodes: 'referrals.getAffiliatePromoCodes',
    getGCSObjectsSignedURL: 'cloud.getGCSObjectsSignedURL',
    getGCSObjects: 'cloud.getGCSObjects',
    moveGCSObjects: 'cloud.moveGCSObjects',
    copyGCSObjects: 'cloud.copyGCSObjects',
    deleteGCSObjects: 'cloud.deleteGCSObjects',
    downloadGCSObjects: 'cloud.downloadGCSObjects',
    getGCSFile: 'cloud.getGCSFile',
    uploadGCSObjectToYoutube: 'cloud.uploadGCSObjectToYoutube'
};
