import { Injectable } from '@angular/core';

import { WindowRef } from './window.service';
import { ApiEndpoints } from '../../core/constants/ApiEndpoints';
import { UserActions } from '../../store/actions/user.actions';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { HelpersService } from '../../core/services/helpers.service';

@Injectable()
export class ConnectionsService {
    private window: any;
    private readonly googleDisconnectActionKey: string = 'googleDisconnect';
    private readonly facebookDisconnectActionKey: string = 'facebookDisconnect';
    private readonly battleNetDisconnectActionKey: string = 'battleNetDisconnect';
    private readonly discordDisconnectActionKey: string = 'discordDisconnect';

    constructor(windowRef: WindowRef,
                private userActions: UserActions,
                private helpersService: HelpersService) {
        this.window = windowRef.nativeWindow;
    }

    private connectWithProvider(url: string, queryParams: any) {
        const redirectUrl = url + (queryParams.returnTo ? '?returnTo=' + queryParams.returnTo : '');
        this.window.location.href = redirectUrl;
    }

    private disconnectFromProvider(actionKey: string, userId: string) {
        this.userActions[actionKey](userId);
    }

    public connectWithGoogle(queryParams: any) {
        this.connectWithProvider(ApiEndpoints.googleLogin, queryParams);
    }

    public connectWithYoutube(queryParams?: any) {
        queryParams = queryParams ? queryParams : {
            returnTo: this.helpersService.getCurrentFullPath()
        };
        this.connectWithProvider(ApiEndpoints.youtubeLogin, queryParams);
    }

    public disconnectFromGoogle(userId: string) {
        this.disconnectFromProvider(this.googleDisconnectActionKey, userId);
    }

    public connectWithFacebook(queryParams: any) {
        this.connectWithProvider(ApiEndpoints.fbLogin, queryParams);
    }

    public disconnectFromFacebook(userId: string) {
        this.disconnectFromProvider(this.facebookDisconnectActionKey, userId);
    }

    public connectWithBattleNet(queryParams: any) {
        this.connectWithProvider(ApiEndpoints.bnetLogin, queryParams);
    }

    public disconnectFromBattleNet(userId: string) {
        this.disconnectFromProvider(this.battleNetDisconnectActionKey, userId);
    }

    public connectWithDiscord(queryParams: any) {
        this.connectWithProvider(ApiEndpoints.discordLogin, queryParams);
    }

    public disconnectFromDiscord(userId: string) {
        this.disconnectFromProvider(this.discordDisconnectActionKey, userId);
    }
}